const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'sharegallery';
const namespace = 'ui-pdp-icon ui-pdp-icon--share-gallery';

const IconShareGallery = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconShareGallery.propTypes = {
  className: string.isRequired,
};

module.exports = React.memo(IconShareGallery);
exports = module.exports;
exports.ICON_ID = ICON_ID;
